import React, { useState, useEffect } from "react"
import NestedMenu from "./NestedMenu"
import AvenueLink from "UI/AvenueLink"
import { Icon, Box } from "UI"
import { useMedia } from "helpers/useMedia.js"
import { NavListItem, IconContainer, FirstLevel } from "./style"

const NavBar = ({ label, menu, link }) => {
  const [subMenu, setSubMenu] = useState(false)

  const handleClick = () => {
    setSubMenu(!subMenu)
  }

  const isDesktop = useMedia(
    // Media queries

    ["(min-width: 1024px)"],
    //options
    [true],
    // default
    false
  )

  useEffect(() => {
    setSubMenu(false)
  }, [isDesktop])

  return (
    <NavListItem menu={menu}>
      <FirstLevel>
        <AvenueLink to={link}>
          <Box height={["60px"]} justifyContent={["center"]}>
            <span>{label}</span>
          </Box>
        </AvenueLink>
        {menu.length > 0 && (
          <IconContainer
            onClick={() => {
              !isDesktop && handleClick()
            }}
          >
            <Icon
              name="ll-arrow"
              width={["11px"]}
              height={["16px"]}
              color="#9FE5FF"
              transform={
                menu.length > 0 && subMenu ? "rotate(-90deg)" : "rotate(90deg)"
              }
            />
          </IconContainer>
        )}
      </FirstLevel>
      {menu && menu.length > 0 && <NestedMenu menu={menu} subMenu={subMenu} />}
    </NavListItem>
  )
}

export default NavBar
