import React, { useState } from "react"
import {
  TextInputContainer,
  TextInputErrorMessage,
  TextInputWrapper,
  Input,
} from "./style"
import { Label, FloatingLabel } from "UI/Form/style.js"
import { Icon as ErrorIcon } from "UI/Icon"

const TextInput = ({
  name,
  placeholder,
  defaultValue,
  register,
  watch,
  type,
  className,
  errorMessage,
  errorIcon,
  errors,
  label,
  floatingLabel,
  Icon,
  required,
  children,
}) => {
  const [fieldActive, setFieldActive] = useState(false)
  const value = watch && watch(`${name}`)
  return (
    <TextInputContainer
      type={type}
      fieldActive={fieldActive}
      className={className}
      area={type === "textarea"}
    >
      {label && <Label htmlFor={name}>{label}</Label>}

      <TextInputWrapper fieldActive={fieldActive} area={type === "textarea"}>
        {floatingLabel && (
          <FloatingLabel
            area={type === "textarea"}
            icon={Icon}
            fieldActive={fieldActive}
            htmlFor={name}
          >
            {floatingLabel}
          </FloatingLabel>
        )}

        {Icon && <Icon />}
        <Input
          floatingLabel={floatingLabel}
          as={type === "textarea" && "textarea"}
          textarea={type === "textarea"}
          name={name}
          placeholder={placeholder || ""}
          defaultValue={defaultValue}
          type={type}
          id={name}
          onFocus={() => setFieldActive(true)}
          onBlur={() => !value?.length && setFieldActive(false)}
          icon={Icon}
          ref={register && register({ required: required || false })}
        />
        {children}
        {errors && errors[name] && (
          <TextInputErrorMessage>
            {errorIcon && <ErrorIcon name={errorIcon} />}
            {errorMessage}
          </TextInputErrorMessage>
        )}
      </TextInputWrapper>
    </TextInputContainer>
  )
}

export default TextInput

TextInput.defaultProps = {
  type: "text",
}
