import React from "react"
import { Title } from "./style"

import AvenueLink from "UI/AvenueLink"
import HTMLReactParser from "html-react-parser"

const TitleComponent = ({ link, centered, outerLink, title }) => {
  return (
    <>
      {!link || outerLink ? (
        <Title centered={centered}>{HTMLReactParser(title)}</Title>
      ) : (
        <Title centered={centered}>
          <AvenueLink to={link}>{HTMLReactParser(title)}</AvenueLink>
        </Title>
      )}
    </>
  )
}

export default TitleComponent
