import styled from "styled-components"
// import { device } from "breakpoints"
export const SocialLinksContainer = styled.div`
  display: flex;
  align-items: flex-end;

  a {
    margin-right: ${(props) => props.gap || "23px"};
    :last-of-type {
      margin-right: 0;
    }
    svg {
      display: block;
      height: ${(props) => props.size || "20px"};
      width: ${(props) => props.size || "20px"};

      ${(props) =>
        props.color
          ? `
        g,
        path {
          fill: ${(props) => props.color};
        }
      `
          : ""}
    }

    :hover {
      opacity: 0.6;
      transition: 70ms;
    }
  }
`
