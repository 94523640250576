import React from "react"

import { SocialLinksContainer } from "./style.js"

import { ReactComponent as FacebookLogo } from "./facebook-logo.svg"
import { ReactComponent as InstagramLogo } from "./instagram-logo.svg"
import { ReactComponent as TwitterLogo } from "./twitter-logo.svg"
import { ReactComponent as LinkedInLogo } from "./linked-in-logo.svg"
import { ReactComponent as YoutubeLogo } from "./youtube-logo.svg"

const SocialLinks = ({
  instagram,
  facebook,
  linkedIn,
  twitter,
  youtube,
  color,
  size,
  gap,
}) => {
  return (
    <SocialLinksContainer color={color} size={size} gap={gap}>
      {instagram && (
        <a href={instagram} target="_blank" rel="noopener noreferrer">
          <InstagramLogo />
        </a>
      )}

      {twitter && (
        <a href={twitter} target="_blank" rel="noopener noreferrer">
          <TwitterLogo />
        </a>
      )}
      {facebook && (
        <a href={facebook} target="_blank" rel="noopener noreferrer">
          <FacebookLogo />
        </a>
      )}

      {linkedIn && (
        <a href={linkedIn} target="_blank" rel="noopener noreferrer">
          <LinkedInLogo />
        </a>
      )}
      {youtube && (
        <a href={youtube} target="_blank" rel="noopener noreferrer">
          <YoutubeLogo />
        </a>
      )}
    </SocialLinksContainer>
  )
}

export default SocialLinks
