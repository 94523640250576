import React, { useState } from "react"

import {
  SelectContainer,
  ArrowContainer,
  SelectedValue,
  CustomOption,
} from "./style"
import { ReactComponent as Arrow } from "./down-arrow.svg"

const SelectInput = ({
  className,
  placeholder,
  options,
  value,
  defaultValue,
  debug,
  name,
  onChange,
  ...props
}) => {
  const [selectedOption, setSelectedOption] = useState(value)

  const customSingleValue = () => {
    return (
      <SelectedValue>
        <span>Filter results</span>
      </SelectedValue>
    )
  }
  const customOption = (props) => {
    return (
      <CustomOption {...props} {...props.innerProps}>
        <span>{props.children}</span>
      </CustomOption>
    )
  }

  const customDropdownIndicator = (props) => {
    return (
      <ArrowContainer open={props.selectProps.menuIsOpen}>
        <Arrow />
      </ArrowContainer>
    )
  }

  const handleChange = (e) => {
    setSelectedOption(e)
    onChange && onChange(e)
  }

  return (
    <SelectContainer
      className={className}
      menuIsOpen={debug}
      classNamePrefix="basic"
      isSearchable={false}
      autoFocus={false}
      components={{
        SingleValue: customSingleValue,
        DropdownIndicator: customDropdownIndicator,
        Placeholder: customSingleValue,
        Option: customOption,
      }}
      value={selectedOption}
      onChange={(e) => handleChange(e)}
      options={options}
      name={name}
      id={name}
      {...props}
    />
  )
}

export default SelectInput
