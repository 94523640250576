import React from "react"
import { Box, Text, Button, MainColumn } from "UI"
import { HeroImage, Arrow, BigArrow } from "./style"

const HomeHeader = ({ pageContext }) => {
  const { title, headerSettings, featuredImage } = pageContext
  const { customPageTitle, headerPage, headerText } = headerSettings
  const pageTitle = customPageTitle || title
  const heroImage = featuredImage?.node

  const Arrows = () => {
    return (
      <>
        {/* 1 */}
        <Arrow
          name="ll-arrow"
          color="#47C5F3"
          width={[42, 58, 45, 115]}
          top={[70, 65, 85, 140, 245]}
          left={[-10, -20, -65, -170]}
          opacity={0.9}
        />
        {/* 2 */}
        <Arrow
          name="ll-arrow"
          color="#008CD1"
          width={[14, 19, null, 39]}
          top={[130, 140, 160, 280, 385]}
          left={[25, 30, -30, -70]}
          opacity={0.9}
        />

        {/* 3 */}
        <Arrow
          name="ll-arrow"
          color="#008CD1"
          width={[62, 107, 130, 181]}
          top={[170, "auto"]}
          bottom={["auto", -20, -40]}
          right={[30, 60, null, -50]}
          opacity={0.8}
        />

        {/* 4 */}
        <Arrow
          name="ll-arrow"
          color="#47C5F3"
          width={[18, 27, 40, 53]}
          top={[165, "auto"]}
          bottom={["auto", 100, 110, 160]}
          right={[25, 50, null, -70]}
          opacity={0.9}
        />
      </>
    )
  }

  return (
    <Box m="0 auto" width="100%" background="#F5F5F5">
      <MainColumn>
        <Box m={["0 -20px", null, 0]} position="relative">
          <Arrows />
          <Box id="header" direction={["column", "row"]} width="100%">
            {/* text block */}
            <Box
              pt={[25, 90, null, 190, 290]}
              pb={[35, 80, null, 160, 250]}
              pl={[20, 73, 0]}
              pr={[20, 50]}
              width={[null, "50%"]}
              order={[2, 1]}
              position="relative"
              zIndex={3}
            >
              <Box direction="column" zIndex={3} maxWidth={[null, 300, 460]}>
                <Text as="h1" fontSize={[36, null, 60, 72]} mb={[15, null, 35]}>
                  {pageTitle}
                </Text>
                <Text
                  color="#575A5E"
                  fontSize={[18, null, 22, 24]}
                  mb={[20, null, 30]}
                >
                  {headerText}
                </Text>

                <Button to={headerPage.uri} zIndex={3}>
                  Learn more
                </Button>
              </Box>

              {/* overlayed arrow */}
              <BigArrow
                display={["none", "block"]}
                name="brand-arrow"
                position="absolute"
                top="50%"
                transform="translateY(-50%)"
                left={[-20, -50, -150, -180, -460]}
                width={[null, 530, 720, 875, 1200]}
              />
            </Box>

            {/* img block */}
            <Box
              position="relative"
              width={[null, "50%"]}
              height={[248, "auto"]}
              order={[1, 2]}
            >
              {heroImage && <HeroImage fluid={heroImage} />}
            </Box>
          </Box>
        </Box>
      </MainColumn>
    </Box>
  )
}

export default HomeHeader
