import React, { useContext } from "react"
import { HeroImage, Arrow, BigArrow } from "./style"
import { Box, Text, Icon, MainColumn } from "UI"
import { Breadcrumbs } from "../Breadcrumbs"
import { Button } from "../Button"
import { PopUpContext } from "context/PopUpContext"
import { PopUp } from "UI/PopUp"
import { CustomEmbeddedVideo } from "../../CustomBlocks/CustomEmbeddedVideo"

const InternalHeader = ({ pageContext, variant }) => {
  const { isPopUpOpen, setPopUpOpen } = useContext(PopUpContext)
  const { title, featuredImage, ancestors, headerSettings } = pageContext
  const { customPageTitle, headerText, videoModal } = headerSettings || {}
  const pageTitle = customPageTitle || title
  const heroImage = featuredImage?.node
  const togglePopUp = () => {
    setPopUpOpen(!isPopUpOpen)
  }

  const Arrows = () => {
    return (
      <>
        {/* 1 */}
        <Arrow
          name="ll-arrow"
          color="#47C5F3"
          width={[42, 58, 40, 115]}
          top={[60, 100, 125, 105, 210]}
          left={[-10, -20, -54, -170]}
          opacity={0.9}
        />
        {/* 2 */}
        <Arrow
          name="ll-arrow"
          color="#008CD1"
          width={[14, 19, null, 39]}
          top={[120, 175, 190, 256, 356]}
          left={[25, 30, -35, -70]}
          opacity={0.9}
        />

        {/* 3 */}
        <Arrow
          name="ll-arrow"
          color={["#47C5F3"]}
          width={[62, 107, 138]}
          top={[120, "auto"]}
          bottom={["auto", -20, -40]}
          right={[30, 60, null, 0]}
          opacity={0.9}
        />

        {/* 4 */}
        <Arrow
          name="ll-arrow"
          color={["#008CD1"]}
          width={[18, 27, 35]}
          top={[110, "auto"]}
          bottom={["auto", 100, 140, 140]}
          right={[25, 50, null, -50]}
          opacity={0.9}
        />
      </>
    )
  }

  return (
    <Box
      m="0 auto"
      width="100%"
      mb={[25, 40, 75]}
      background="#F5F5F5"
      id="header"
    >
      <MainColumn>
        <Box m={["0 -20px", null, 0]} position="relative">
          <Arrows />
          <PopUp>
            <CustomEmbeddedVideo video={videoModal} shouldPlay={isPopUpOpen} />
          </PopUp>
          <Box id="header" direction={["column", "row"]} width="100%">
            {/* text block */}
            <Box
              pt={[28, 70, 130, null, 240]}
              pb={[30, 100, 130, null, 270]}
              pl={[20, 73, 0]}
              width={[null, "55%"]}
              order={[2, 1]}
              zIndex={3}
              background={["#F5F5F5", "none"]}
            >
              <Box direction="column" zIndex={3} maxWidth={[null, 300, 500]}>
                <Breadcrumbs
                  ancestors={ancestors?.nodes}
                  currentPage={title}
                  variant={variant}
                />
                <Text
                  as="h1"
                  fontSize={[32, null, variant === "casestudy" ? 60 : 48]}
                  width="100%"
                >
                  {pageTitle}
                </Text>
                {variant === "casestudy" && (
                  <>
                    <Text
                      as="p"
                      fontSize={[18, null, 24]}
                      mt={["16px", null, 24]}
                      width={["calc(100% - 10px)", "345px", "455px"]}
                    >
                      {headerText}
                    </Text>
                    {videoModal && (
                      <Button
                        variant="primary"
                        mt={["20px", null, 40]}
                        onClick={togglePopUp}
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <Icon
                            name="play"
                            size={["14px", 24]}
                            color="#fff"
                            mr={["8px", null, "10px"]}
                          />
                          <Text as="h4" color="#fff">
                            Play video
                          </Text>
                        </Box>
                      </Button>
                    )}
                  </>
                )}
              </Box>

              {/* overlayed arrow */}
              <BigArrow
                display={["none", "block"]}
                name="brand-arrow"
                position="absolute"
                top="50%"
                transform="translateY(-50%)"
                left={[-20, -90, -140, -250]}
                width={[null, 530, 720, 875]}
              />
            </Box>
            <Box
              position="relative"
              width={[null, "50%"]}
              height={[200, "auto"]}
              order={[1, 2]}
            >
              {heroImage && <HeroImage fluid={heroImage} />}
            </Box>
          </Box>
        </Box>
      </MainColumn>
    </Box>
  )
}

export default InternalHeader
