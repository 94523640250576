import styled from "styled-components"
import { device } from "utils/breakpoints.js"
import { ErrorMessage, InputSectionWrapper } from "../../Form/style"

export const TextInputContainer = styled(InputSectionWrapper)`
  flex-direction: column;
  width: 100%;

  svg {
    position: absolute;
    top: ${(props) => (props.area ? "18px" : "50%")};
    left: 17px;
    width: 24px;
    height: 24px;
    transform: ${(props) => (props.area ? null : "translateY(-50%);")};

    @media ${device.desktop} {
      top: ${(props) => (props.area ? "26px" : "50%")};
      left: 20px;
    }
    path {
      transition: 100ms;
      fill: ${(props) =>
        props.fieldActive ? props.theme.primaryColor : "#d3d2ce"};
    }
  }
`

export const TextInputWrapper = styled.div`
  position: relative;
`

export const TextInputErrorMessage = styled(ErrorMessage)`
  position: absolute;
  left: 0;
  bottom: -20px;

  @media ${device.desktop} {
    bottom: -28px;
  }
`

export const Input = styled.input`
  display: block;
  outline: none;
  border: none;
  resize: none;
  width: 100%;

  /* padding settings */
  padding: 0 15px;
  padding-left: ${(props) => props.icon && "54px"};
  padding-top: ${(props) => props.floatingLabel && "15px"};
  padding-top: ${(props) => props.textarea && "20px"};

  /* custom styles */
  border-radius: 4px;
  font-size: 16px;
  height: 60px;
  height: ${(props) => props.textarea && "200px"};
  border: 1px solid hotpink;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.05);
  background: #eee;

  @media ${device.desktop} {
    /* padding settings */
    padding: 0 20px;
    padding-left: ${(props) => props.icon && "60px"};
    padding-top: ${(props) => props.floatingLabel && "15px"};
    padding-top: ${(props) => props.textarea && "20px"};

    /* custom styles */
    height: 72px;
    height: ${(props) => props.textarea && "200px"};
    font-size: 20px;
  }
`
