import styled from "styled-components"
import { device } from "breakpoints"

import { Link } from "gatsby"
export const BreadcrumbsContainer = styled.div`
  display: none;

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
`

export const PrimaryLink = styled(Link)`
  font-size: 12px;
  line-height: 100%;
  font-weight: 600;
  font-family: ${(props) => props.theme.headerFont};
  color: #1870b0;
  color: ${(props) => props.alt && "white"};

  transition: 120ms;
  text-transform: uppercase;
  :hover {
    color: #008cd1;
    color: ${(props) => props.alt && "#1870B0"};
  }
`

export const PresentPage = styled.span`
  font-size: 12px;
  line-height: 100%;
  font-weight: 600;
  font-family: ${(props) => props.theme.headerFont};
  text-transform: uppercase;

  color: #aeb2b6;
  color: ${(props) => props.alt && "#47C5F3"};
  pointer-events: none;
`
