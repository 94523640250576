import React from "react"
import { ReactComponent as Search } from "icons/avenue/search.svg"
import { ReactComponent as MailIcon } from "icons/avenue/mail.svg"
import { ReactComponent as MessageIcon } from "icons/avenue/message.svg"
import { ReactComponent as NameIcon } from "icons/avenue/name.svg"
import { ReactComponent as PhoneIcon } from "icons/avenue/phone.svg"
import { ReactComponent as ArrowLeft } from "icons/avenue/arrow-left.svg"

const iconMap = {
  search: { icon: Search },
  mail: { icon: MailIcon },
  message: { icon: MessageIcon },
  name: { icon: NameIcon },
  phone: { icon: PhoneIcon },
  "arrow-left": { icon: ArrowLeft },
}

export const hasIcon = name => {
  return !!iconMap[name]
}

export const Icon = ({ name, className }) => {
  return iconMap[name]
    ? React.createElement(iconMap[name].icon, { className: className || name })
    : false
}
