import React from "react"
import AvenueLink from "UI/AvenueLink"
import ThirdLevelMenu from "./ThirdLevelMenu"
import { NestedNav, NestedNavListItem } from "./style"

const NestedMenu = ({ menu, subMenu }) => {
  return (
    <NestedNav subMenu={subMenu}>
      <ul>
        {menu.map((item, index) => {
          const thirdMenu = item?.wpChildren?.nodes.length > 0
          if (!item.pageSettings.hideFromNav) {
            return (
              <NestedNavListItem key={index}>
                <AvenueLink to={item.uri}>{item.title}</AvenueLink>
                {thirdMenu && <ThirdLevelMenu menu={item?.wpChildren?.nodes} />}
              </NestedNavListItem>
            )
          } else return null
        })}
      </ul>
    </NestedNav>
  )
}

export default NestedMenu
