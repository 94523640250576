import React, { useContext, useRef, useEffect } from "react"
import { PopUpContext } from "context/PopUpContext"
import { useEscHandler } from "helpers/useEscHandler"
import { useOutsiderAlerter } from "helpers/useOutsiderAlerter"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { Box, Icon } from "UI"
import {
  PopUpContainer,
  PopUpBackground,
  ClosePopUpButton,
  PopUpMain,
} from "./style"

const PopUp = ({ children }) => {
  const { isPopUpOpen, setPopUpOpen } = useContext(PopUpContext)
  const popUpRef = useRef(null)

  const closePopUp = () => {
    setPopUpOpen(false)
  }
  useOutsiderAlerter(popUpRef, () => closePopUp())
  useEscHandler(() => closePopUp())

  useEffect(() => {
    const targetElement = popUpRef.current

    if (isPopUpOpen) {
      disableBodyScroll(targetElement)
    } else {
      enableBodyScroll(targetElement)
    }
  }, [isPopUpOpen])

  return (
    <PopUpContainer isPopUpOpen={isPopUpOpen}>
      <PopUpBackground />

      <PopUpMain ref={popUpRef}>
        <Box position="relative" width="100%" maxWidth="100%" maxHeight="100%">
          <ClosePopUpButton label="close pop up" onClick={closePopUp}>
            <Icon name="close-modal" size="20px" color="#fff" />
          </ClosePopUpButton>
          {children}
        </Box>
      </PopUpMain>
    </PopUpContainer>
  )
}

export default PopUp
