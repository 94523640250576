const colors = {
  indigo: "#008CD1",
  celeste: "#47C5F3",
  orange: "#FD8211",
  charcoal: "#303030",
  lightBlue: "#9FE5FF",
  darkOrange: "#EC5500",
  darkerOrange: "#D94E00",
  darkBlue: "#1870B0",
  lightGrey: "#F5F5F5",
  softGrey: "#CED0D2",
  darkText: "#000000",
  lightText: "#575A5E",
  dodgerBlue: "#0C5F9B",
}

const primaryColor = colors.indigo
const secondaryColor = colors.orange

const theme = {
  primaryColor: primaryColor,
  secondaryColor: secondaryColor,
  linkColor: "#fff",
  btnColor: secondaryColor,
  btnHoverColor: colors.darkOrange,
  btnTextColor: "#FFF",
  mobileGutter: 20,
  desktopGutter: 40,
  mainMaxWidth: 1160,
  xsGridGap: 20,
  smGridGap: 20,
  mdGridGap: 40,
  lgGridGap: 40,
  breakpoints: ["768px", "1024px", "1280px", "1660px"],
  headerFont: `"Montserrat", "Arial", "sans-serif"`,
  bodyFont: `"Roboto", "Arial", "sans-serif"`,
  colors,
}

export default theme
