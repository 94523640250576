import React, { useState, useEffect } from "react"
import {
  Container,
  ContactContainer,
  SocialContainer,
  LinkWrapper,
  ContactText,
  SVGWrapper,
} from "./style"
import { SocialLinks } from "UI/SocialLinks"
import { useStaticQuery, graphql } from "gatsby"
import { ReactComponent as PhoneIcon } from "./phone.svg"
import { ReactComponent as EmailIcon } from "./email.svg"

const EmailContact = ({ data }) => {
  const { link } = data
  return (
    <LinkWrapper href={`mailto: ${link}`}>
      <SVGWrapper>
        <EmailIcon />
      </SVGWrapper>
      <ContactText>{link}</ContactText>
    </LinkWrapper>
  )
}

const PhoneContact = ({ data }) => {
  const { link } = data
  return (
    <LinkWrapper href={`tel:${link}`}>
      <SVGWrapper>
        <PhoneIcon />
      </SVGWrapper>
      <ContactText>{link}</ContactText>
    </LinkWrapper>
  )
}

const ContactDetails = ({ data }) => {
  const { email, phone } = data
  return (
    <ContactContainer>
      {phone && <PhoneContact data={{ link: phone }} />}
      {email && <EmailContact data={{ link: email }} />}
    </ContactContainer>
  )
}

const SocialDetails = ({ data }) => {
  const { facebookLink, linkedinLink, youtubeLink } = data || {}

  return (
    <SocialContainer>
      <SocialLinks
        facebook={facebookLink}
        linkedIn={linkedinLink}
        youtube={youtubeLink}
        color="#008CD1"
        size={"16px"}
        gap={"20px"}
      />
    </SocialContainer>
  )
}

const HeaderNavigationStrip = () => {
  const [countryCode, setCountryCode] = useState("AU")

  useEffect(() => {
    fetch(`https://www.cloudflare.com/cdn-cgi/trace`).then((response) => {
      return response.text().then(function (text) {
        const country = text.match(/loc=(.+)/)[1].toLowerCase()

        setCountryCode(country)
      })
    })
  }, [])

  const data = useStaticQuery(
    graphql`
      {
        wp {
          siteOptions {
            siteSettings {
              auEmail
              auPhone
              gbEmail
              gbPhone
              nzEmail
              nzPhone
              linkedinLink
              facebookLink
              twitterLink
              youtubeLink
            }
          }
        }
      }
    `
  )

  const metadata = data?.wp?.siteOptions?.siteSettings

  const email = countryCode && metadata[`${countryCode}Email`]
  const phone = countryCode && metadata[`${countryCode}Phone`]
  const facebookLink = metadata?.facebookLink
  const linkedinLink = metadata?.linkedinLink
  const youtubeLink = metadata?.youtubeLink

  return (
    <Container>
      <ContactDetails data={{ email, phone }} />
      <SocialDetails data={{ facebookLink, linkedinLink, youtubeLink }} />
    </Container>
  )
}
export default HeaderNavigationStrip
