import React from "react"
import { CategoriesContainer } from "./style.js"

const Categories = ({ className, categories }) => {
  return (
    <CategoriesContainer className={className} >
      {categories.map((cat, index) => {
        return <span key={index}>{cat}</span>
      })}
    </CategoriesContainer>
  )
}

export default Categories
