import React from "react"
import { Link } from "gatsby"
import { Box } from "UI"
import { ColTitleLink, ColTitle, ColLink } from "./style"

const FooterCol = ({ heading, links }) => {
  return (
    <Box width={["45%", null, "45%"]}>
      {heading?.uri ? (
        <Link to={heading?.uri}>
          <ColTitleLink>{heading?.title}</ColTitleLink>
        </Link>
      ) : (
        <ColTitle>{heading?.title}</ColTitle>
      )}

      {links?.map((link, index) => {
        return (
          <Box mt={[12, null, 13]} key={Math.random() + index}>
            <Link to={link?.uri}>
              <ColLink>{link?.title}</ColLink>
            </Link>
          </Box>
        )
      })}
    </Box>
  )
}
export default FooterCol
