import styled from "styled-components"
import {
  compose,
  space,
  position,
  color,
  shadow,
  background,
  border,
  layout,
  flexbox,
  typography,
  system,
} from "styled-system"

const config = {
  flexDirection: {
    property: "flexDirection",
  },
}

config.direction = config.flexDirection

const custom = system(config)

const Box = styled.div`
  display: flex;
  flex-direction: column;

  ${compose(
    space,
    position,
    color,
    shadow,
    background,
    border,
    layout,
    typography,
    flexbox,
    custom
  )}
`

export default Box
