import styled from "styled-components"
import { device } from "breakpoints"
import { Link } from "gatsby"
import {
  compose,
  space,
  layout,
  typography,
  color,
  flexbox,
} from "styled-system"

const styledSystemProps = compose(space, layout, typography, color, flexbox)

export const AvenueButton = styled(Link)`
  border-radius: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 23px;
  background: #fd8211;
  font-size: 16px;
  color: white;
  font-weight: bold;
  align-self: flex-start;
  justify-self: flex-start;
  transition: 120ms;
  @media ${device.desktop} {
    font-size: 20px;
    padding: 15px 26px;

    ${styledSystemProps}
  }

  ${styledSystemProps}

  :hover {
    cursor: pointer;
    background: #de720e;
  }
`

export const Label = styled.span`
  position: relative;
`
