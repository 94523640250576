import React from "react"
import { Box, Text, Button, MainColumn, Line } from "UI"
import { ArrowsNoOverflow, ArrowsOverflow } from "./Arrows"
import { useStaticQuery, graphql } from "gatsby"

const FooterCTA = () => {
  const data = useStaticQuery(
    graphql`
      {
        wp {
          siteOptions {
            siteSettings {
              contactStrip {
                heading
                page {
                  ... on WpPage {
                    uri
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  const {
    heading,
    page: { uri },
  } = data.wp.siteOptions.siteSettings.contactStrip

  return (
    <Box mt={[10, 90, 120]} background="#008CD1">
      <Box
        position="relative"
        maxWidth={1430}
        margin="0 auto"
        // mt={[10, 90, 120]}
        width="100%"
      >
        <ArrowsOverflow />
        <Box position="relative" overflow="hidden">
          <ArrowsNoOverflow />
          <MainColumn>
            <Box
              pt={[70, 90, 125]}
              pb={[45, 80, 115]}
              pl={[25, 25, 0]}
              pr={[25, 25, 0]}
              position="relative"
              zIndex={1}
              direction={["column", "row"]}
              justifyContent="space-between"
            >
              <Text
                as="h2"
                fontSize={[28, 32, 36]}
                mb={[32, null]}
                color="#fff"
                maxWidth={["100%", "50%"]}
                textAlign={["center", "left"]}
              >
                {heading}
              </Text>
              <Button to={uri} zIndex={1} alignSelf="center">
                Get in touch
              </Button>
            </Box>
          </MainColumn>
        </Box>
      </Box>
      <Line color="#1870B0" border="2px solid" />
    </Box>
  )
}

export default FooterCTA
