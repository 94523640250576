import styled from "styled-components"
import { device } from "breakpoints"
import { Button } from "UI/Button"
import {
  TextInputContainer,
  TextInputErrorMessage,
} from "UI/Form/TextInput/style"

export const SubscribePageContainer = styled.div`
  position: relative;
  overflow: hidden;
  background: ${(props) => props.theme.primaryColor};
  max-width: 1430px;
  margin: 0 auto;
  color: #fff;
  text-align: center;
  padding-top: 29px;
  padding-bottom: 40px;
  width: 100%;

  @media ${device.tablet} {
    padding: 20px 0;
    text-align: left;
  }

  @media ${device.desktop} {
    padding: 40px 0;
    text-align: left;
  }

  > div:not(.icon) {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${device.tablet} {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  ${TextInputContainer} {
    ${TextInputErrorMessage} {
      .icon {
        display: inline-block;
        margin: 0 4px;
        transform: translateY(5px);
      }

      svg {
        position: relative;
        transform: none;
        width: 20px;
        height: 20px;
        top: 0;
        left: 0;
        margin: 10px 5px 0 0;

        path:nth-of-type(1) {
          fill: #d94e00;
        }
        path:nth-of-type(2) {
          fill: #fff;
        }
      }
    }

    button svg {
      position: relative;
      transform: none;
      left: 1px;
      width: 10.7px;

      path {
        fill: #fff;
      }
    }

    p {
      color: #fff;
      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
    }
  }
`

export const SubscribeForm = styled.form`
  div[type="email"] {
    border: none;
    max-width: 360px;
    width: 100%;
    margin: 0 auto;
  }

  input#email {
    color: #575a5e;
    border: none;
    background: #fff;
    box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.15);
    height: 50px;
    width: 100%;
    border-radius: 50px;
    padding-left: 25px;
    padding-right: 60px;
    margin-bottom: 10px;
    @media ${device.tablet} {
      height: 60px;
      width: 360px;
      border-radius: 60px;
    }

    :focus {
      box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.35);
    }

    ::placeholder {
      color: #aeb2b6;
      opacity: 1;
    }
  }
`

export const SubscribeTitle = styled.h3`
  font-size: 24px;
  line-height: 110%;
  color: #fff;
  padding: 0 15px;
  margin-top: 16px;
  margin-bottom: 13px;
  max-width: 450px;

  @media ${device.tablet} {
    line-height: 125%;
    margin-bottom: 25px;
    padding: 0;
  }

  @media ${device.desktop} {
    font-size: 32px;
    max-width: 500px;
  }
`

export const SubscribeSubtitle = styled.p`
  font-size: 16px;
  margin-bottom: ${(props) => (props.submitted ? "130px" : "20px")};
  line-height: 150%;
  max-width: 450px;

  @media ${device.tablet} {
    margin-bottom: ${(props) => (props.submitted ? "74px" : "20px")};
  }

  @media ${device.desktop} {
    margin-bottom: ${(props) => (props.submitted ? "60px" : "20px")};

    max-width: 520px;
    font-size: 18px;
  }
`

export const SubmitButton = styled(Button)`
  padding: 0;
  position: absolute;
  cursor: pointer;
  top: 4px;
  right: 4px;
  height: 42px;
  width: 42px;
  border-radius: 50px;

  @media ${device.tablet} {
    top: 6px;
    right: 6px;
    height: 48px;
    width: 48px;
    border-radius: 48px;
  }
`
