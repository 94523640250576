import styled from "styled-components"
import { device } from "breakpoints"

export const Container = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  position: absolute;
  z-index: 999;
  right: 74px;
  top: 22px;

  @media ${device.desktop} {
    position: relative;
    min-height: 57px;
    top: unset;
    right: unset;
    margin: 0 auto;
    padding: 12px 20px 18px 20px;
    background-color: #f5f5f5;
    width: 100%;
    max-width: 1160px;
  }
`
export const ContactContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  margin-right: 10px;
  transform: translateY(5px);

  svg {
    height: 20px;
    width: 20px;
  }

  @media (min-width: 375px) {
    margin-right: 18px;
    gap: 18px;
  }

  @media ${device.desktop} {
    svg {
      height: 16px;
      width: 16px;
    }
  }
`

export const ContactText = styled.div`
  display: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #303030;
  margin-left: 12px;
  margin-bottom: 3px;

  @media ${device.desktop} {
    display: initial;
  }
`

export const SVGWrapper = styled.div``

export const SocialContainer = styled.div`
  display: none;

  @media ${device.desktop} {
    display: flex;
    align-items: center;
  }
`

export const LinkWrapper = styled.a`
  display: flex;
  align-items: center;
`
