import styled from "styled-components"
import {
  compose,
  space,
  position,
  typography,
  color,
  background,
  border,
  layout,
  flexbox,
} from "styled-system"

const Text = styled.p`
  ${compose(
    space,
    position,
    typography,
    color,
    background,
    border,
    layout,
    flexbox
  )}
`

export default Text
