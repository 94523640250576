import React from "react"
import { ContentWrapper } from "./style"

const Content = ({ children, fullWidth, noIntroStyles }) => {
  return (
    <ContentWrapper fullWidth={fullWidth} noIntroStyles={noIntroStyles}>
      {children}
    </ContentWrapper>
  )
}

export default Content
