import React from "react"
import {
  SubscribeForm,
  SubscribePageContainer,
  SubscribeTitle,
  SubscribeSubtitle,
  SubmitButton,
} from "./style"

import { TextInput } from "UI/Form/TextInput"
import { Box, MainColumn, Icon } from "UI"
import { Arrows } from "./Arrows"
import { useForm } from "UI/Form/useForm"
import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"

const CREATE_SUBSCRIPTION = gql`
  mutation CreateSubscription($email: String!) {
    createSubscription(
      input: { clientMutationId: "Email Subscription", email: $email }
    ) {
      data
      success
    }
  }
`

const EmailSubscription = () => {
  const [createSubscription] = useMutation(CREATE_SUBSCRIPTION)
  const { handleSubmit, inputProps, setSubmitted, submitted } = useForm()

  const onSubmit = (values) => {
    setSubmitted(true)

    createSubscription({
      variables: {
        email: values.email.length ? values.email : "n/a",
      },
    })
  }

  return (
    <Box background="#008CD1">
      <SubscribePageContainer>
        <Arrows />
        <MainColumn>
          <Box pr={[0, 27]}>
            <Icon
              name="newsletter"
              width={["34.5px", "46px"]}
              height={["34.5px", "46px"]}
            />
          </Box>
          <Box direction={["column"]} zIndex={2} mr={[0, 20]}>
            <SubscribeTitle>
              {submitted
                ? "Thanks for signing up!"
                : "Sign up to our newsletter for the latest news and insights."}
            </SubscribeTitle>
            <SubscribeSubtitle submitted={submitted}>
              {submitted
                ? "You’ll start receiving our updates soon."
                : "Plus, get exclusive access to our VIP content."}
            </SubscribeSubtitle>
          </Box>

          <Box
            direction={["column"]}
            width={["100%", "unset"]}
            alignSelf="center"
            ml={[0, 0, 30, 100]}
          >
            {!submitted && (
              <SubscribeForm onSubmit={handleSubmit(onSubmit)}>
                <TextInput
                  name="email"
                  type="email"
                  placeholder="Your email address..."
                  required
                  errorMessage="Please enter a valid email address."
                  errorIcon="c-warning"
                  {...inputProps}
                >
                  <SubmitButton type="submit" label="Submit">
                    <Icon name="ll-arrow" />
                  </SubmitButton>
                </TextInput>
              </SubscribeForm>
            )}
          </Box>
        </MainColumn>
      </SubscribePageContainer>
    </Box>
  )
}

export default EmailSubscription
