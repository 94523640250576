import React from "react"
import { IconSVG } from "./style"

const Icon = ({
  name,
  className,
  size,
  color,
  height,
  width,
  transform,
  onClick,
  opacity,
  ...styleProps
}) => {
  return (
    <IconSVG
      height={size || height}
      width={size || width}
      className={`${className} icon ${name}`}
      src={`/images/icons/${name}.svg`}
      color={color}
      transform={transform}
      onClick={onClick}
      opacity={opacity}
      {...styleProps}
    />
  )
}

export default Icon
