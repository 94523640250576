import styled from "styled-components"
import { device } from "breakpoints"
import AvenueLink from "UI/AvenueLink"
import { Text } from "UI"

export const FooterContainer = styled.div`
  margin-top: 20px;

  overflow: hidden;
`

export const LogoWrapper = styled(AvenueLink)`
  display: flex;
  align-items: center;
  height: auto;
  @media ${device.desktop} {
    margin-right: 30px;
  }

  > svg,
  > img {
    width: 118px;
    height: 28px;
    display: block;
    @media ${device.desktop} {
      width: 134px;
      height: 32px;
    }
  }
`

export const FooterLink = styled(AvenueLink)`
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 15px;
  margin-right: 23px;

  @media ${device.tablet} {
    margin-bottom: 0px;
    margin-right: 30px;
  }

  @media ${device.desktop} {
    font-size: 14px;
  }

  :first-child {
    position: relative;
    ::before {
      position: absolute;
      content: "";
      top: 8px;
      right: -14px;
      width: 4px;
      height: 4px;
      border-radius: 4px;
      background-color: ${(props) => props.theme.colors.celeste};
    }
  }

  :hover {
    color: ${(props) => props.theme.primaryColor};
    transition: 80ms;
  }
`

export const Copyright = styled.div`
  width: 100%;
  p {
    font-size: 12px;
    line-height: 150%;
    color: #575a5e;
    @media ${device.desktop} {
      font-size: 14px;
      line-height: 150%;
    }
  }

  a {
    /* font-weight: 600; */
    text-decoration: none;
    color: ${(props) => props.theme.primaryColor};
    :hover {
      opacity: 0.6;
      transition: 80ms;
    }
  }
`

export const FooterLinksMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media ${device.tablet} {
    flex-direction: row;
  }
`

export const ColTitle = styled(Text)`
  font-family: ${(props) => props.theme.headerFont};
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: #1870b0;

  @media ${device.desktop} {
    font-size: 20px !important;
    line-height: 150%;
  }
`

export const ColTitleLink = styled(ColTitle)`
  :hover {
    color: ${(props) => props.theme.colors.celeste};
  }
`

export const ColLink = styled(Text)`
  font-weight: 700;
  font-size: 12px;
  line-height: 200%;
  color: #575a5e;

  @media ${device.desktop} {
    font-size: 14px;
    line-height: 150%;
  }

  :hover {
    color: ${(props) => props.theme.colors.celeste};
  }
`

export const ImageWrapper = styled.div`
  max-width: 227px;
  width: 100%;
  :last-of-type {
    max-width: 151px;
  }
  /* :last-child {
  } */
`

export const BadgeStyle = styled.a`
  width: 72px;
  height: 122px;
`
