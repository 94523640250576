import React from "react"
import { ThirdLevelContainer, ThirdLEvelListItem } from "./style"
import AvenueLink from "UI/AvenueLink"

const ThirdLevelMenu = ({ menu }) => {
  return (
    <ThirdLevelContainer>
      <ul>
        {menu.map((item, index) => {
          return (
            <ThirdLEvelListItem key={index}>
              <AvenueLink to={item.uri}>{item.title} L3</AvenueLink>
            </ThirdLEvelListItem>
          )
        })}
      </ul>
    </ThirdLevelContainer>
  )
}

export default ThirdLevelMenu
