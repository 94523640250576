import React, { useState, useContext } from "react"
import { NavItem } from "./NavItem"
import Hamburger from "./Hamburger"
import { useStaticQuery, graphql } from "gatsby"
import { PreviewContext } from "context/PreviewContext"
import { HeaderNavigationStrip } from "UI/HeaderNavigationStrip"
import { ReactComponent as Logo } from "images/lanluas-logo.svg"
import { menuOrder } from "helpers/menuOrder"
import {
  LogoWrapper,
  NavBarContainer,
  NavBarWrapper,
  NavWrapper,
  Nav,
  NavList,
} from "./style"

const NavBar = () => {
  const [isNavShown, setNavShown] = useState(false)
  const { previewData } = useContext(PreviewContext)

  const navData = useStaticQuery(
    graphql`
      query {
        pages: allWpPage(
          filter: { isFrontPage: { eq: false }, parentId: { eq: null } }
          sort: { fields: menuOrder, order: ASC }
        ) {
          nodes {
            title
            uri

            pageSettings {
              hideFromNav
            }
            wpChildren {
              nodes {
                ... on WpPage {
                  title
                  menuOrder
                  uri
                  pageSettings {
                    hideFromNav
                  }
                  wpChildren {
                    nodes {
                      ... on WpPage {
                        title
                        menuOrder

                        uri
                        pageSettings {
                          hideFromNav
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  let navMenu = previewData?.pages?.nodes || navData?.pages?.nodes

  navMenu = menuOrder(navMenu)

  const toggleShowNav = () => {
    setNavShown(!isNavShown)
  }

  return (
    <NavBarWrapper>
      <HeaderNavigationStrip id="desktop-hns" />
      <NavBarContainer>
        <LogoWrapper to="/">
          <Logo />
        </LogoWrapper>
        <NavWrapper>
          <Nav isNavShown={isNavShown}>
            <NavList>
              {navMenu?.map((item, index) => {
                if (!item.pageSettings.hideFromNav) {
                  return (
                    <NavItem
                      key={index}
                      label={item.title}
                      link={item.uri}
                      menu={item.wpChildren?.nodes}
                    />
                  )
                }
              })}
            </NavList>
          </Nav>
          {/* <QuickSearchWrapper to="/search">
          <Search />
        </QuickSearchWrapper> */}
          <Hamburger isNavShown={isNavShown} toggleShowNav={toggleShowNav} />
        </NavWrapper>
      </NavBarContainer>
    </NavBarWrapper>
  )
}

export default NavBar
