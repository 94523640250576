import { useEffect } from "react"

const ActiveCampaignTracker = ({ pageContext }) => {
  useEffect(() => {
    console.log("useEffect activecampaign", pageContext.uri)

    // Reset ActiveCampaign tracking
    delete window.visitorGlobalObject
    delete window.vgo
    delete window.visitorGlobalObjectAlias
    ;(function (e, t, o, n, p, r, i) {
      e.visitorGlobalObjectAlias = n
      e[e.visitorGlobalObjectAlias] =
        e[e.visitorGlobalObjectAlias] ||
        function () {
          ;(e[e.visitorGlobalObjectAlias].q =
            e[e.visitorGlobalObjectAlias].q || []).push(arguments)
        }
      e[e.visitorGlobalObjectAlias].l = new Date().getTime()
      r = t.createElement("script")
      r.src = o
      r.async = true
      i = t.getElementsByTagName("script")[0]
      i.parentNode.insertBefore(r, i)
    })(
      window,
      document,
      "https://diffuser-cdn.app-us1.com/diffuser/diffuser.js",
      "vgo"
    )
    window.vgo("setAccount", "224803723")
    window.vgo("setTrackByDefault", true)

    window.vgo("process")

    setTimeout(() => {
      const oUser = window?.visitorGlobalObject?._getVisitor()

      console.log(oUser)

      const localUserEmail = localStorage.getItem("activeCampaignEmail")

      if (localUserEmail) {
        console.log("existing stored user", localUserEmail)
      }

      if (oUser?._email) {
        console.log("existing user", oUser._email)

        // set to localstroage
        localStorage.setItem("activeCampaignEmail", oUser._email)
      }
    }, 1500)

    // set activeCampaignEmail ley to local storage
  }, [pageContext.uri])

  return null
}

export default ActiveCampaignTracker
