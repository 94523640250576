import styled from "styled-components"
import { device } from "breakpoints"
import ReactPlayer from "react-player"

import AvenueImg from "UI/AvenueImg"

export const ReactVideo = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;

  iframe {
    background: black !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
`

export const CustomembeddedVideoButtonContainer = styled.div`
  display: ${(props) => (props.shouldPlay ? "none" : "flex")};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 160px;
  position: absolute;
  bottom: 0;
  left: 0;

  @media ${device.desktop} {
    bottom: 15px;
    left: 15px;
  }

  p {
    font-size: 14px;
    color: #ffffff;
    letter-spacing: 0.44px;
    line-height: 24px;
    text-shadow: 0 2px 10px rgba(0, 0, 0, 0.35);
    transition: 420ms;

    @media ${device.desktop} {
      font-size: 16px;
      letter-spacing: 0.44px;
      line-height: 27px;
    }
  }

  svg {
    transition: 420ms;
    width: 64px;
    height: 64px;
    padding-top: -10px;
    transform: scale(0.4);

    @media ${device.tablet} {
      transform: scale(0.5);
    }

    path {
      fill: #00ff00;
    }
  }
`

export const CustomEmbeddedVideoContainer = styled.div`
  position: relative;
  padding-bottom: ${(props) => (props.internalBlock ? "50%" : "61%")};
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: ${(props) => (props.internalBlock ? "40px" : "0")};
  margin-top: ${(props) => (props.internalBlock ? "40px" : "0")};

  @media ${device.tablet} {
    :hover ${CustomembeddedVideoButtonContainer} {
      p {
        font-size: 18px;
      }
      svg {
        transform: scale(0.5);
        path {
          fill: #0000ff;
        }
        circle {
          stroke: #0000ff;
        }
        @media ${device.tablet} {
          transform: scale(0.6);
        }
      }
    }
  }
  @media ${device.desktop} {
    margin-bottom: ${(props) => (props.internalBlock ? "60px" : "0")};
    margin-top: ${(props) => (props.internalBlock ? "60px" : "0")};
  }
`

export const VideoImageContainer = styled.div`
  display: ${(props) => (props.showVideo ? "none" : "block")};
`
export const VideoContainer = styled.div`
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  display: ${(props) =>
    props.shouldPlay ? "block" : props.showVideo ? "block" : "none"};
`

export const Thumbnail = styled(AvenueImg)`
  display: ${(props) =>
    props.shouldPlay ? "none" : props.showVideo ? "none" : "block"};
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
