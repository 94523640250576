import styled from "styled-components"
import AvenueImg from "UI/AvenueImg"
import { device } from "breakpoints"
import { Icon } from "UI"
export const HeroImage = styled(AvenueImg)`
  position: absolute !important;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  overflow: hidden;
  img {
    object-fit: cover;
  }

  @media ${device.tablet} {
    left: auto;
    right: 0;
    transform: none;
    width: calc(200% - 200px);
  }

  @media ${device.desktop} {
    transform: translateX(40px);
  }
  @media (min-width: 1240px) {
    width: 74vw;
    max-width: 1390px;
    transform: translateX(calc((100vw - 1160px) / 2));
  }

  @media (min-width: 1800px) {
    max-width: 1800px;
  }

  @media (min-width: 2200px) {
    max-width: 2200px;
  }
`

export const Arrow = styled(Icon)`
  position: absolute;
  z-index: 10;
`
export const BigArrow = styled(Icon)`
  height: 100%;
  overflow: hidden;
  z-index: 1;

  svg {
    position: absolute;
    top: 50%;
    transform: translate3d(0%, -50%, 0);
    height: 102%;
    right: 0;
  }
`
