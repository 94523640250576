import React, { useState } from "react"

import { Icon } from "helpers/getIcon"
import {
  CustomEmbeddedVideoContainer,
  CustomembeddedVideoButtonContainer,
  ReactVideo,
  VideoContainer,
  VideoImageContainer,
} from "./style"

const CustomEmbeddedVideo = ({ video, shouldPlay, internalBlock }) => {
  const [showVideo, setShowVideo] = useState(true)
  const shouldMute = shouldPlay ? true : false

  return (
    <CustomEmbeddedVideoContainer
      onClick={() => setShowVideo(true)}
      internalBlock={internalBlock}
    >
      <VideoImageContainer>
        <CustomembeddedVideoButtonContainer shouldPlay={shouldPlay}>
          <Icon name={"play"} />
        </CustomembeddedVideoButtonContainer>
        <VideoContainer showVideo={showVideo} shouldPlay={shouldPlay}>
          <ReactVideo
            controls={true}
            url={video}
            playing={shouldPlay}
            volume={1}
            muted={shouldMute}
          />
        </VideoContainer>
      </VideoImageContainer>
    </CustomEmbeddedVideoContainer>
  )
}

export default CustomEmbeddedVideo
