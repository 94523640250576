import styled from "styled-components"
import { device } from "utils/breakpoints"
import { ReactSVG } from "react-svg"
import { compose, position, space, layout } from "styled-system"

export const IconSVG = styled(ReactSVG)`
  flex-shrink: 0;
  transform: ${(props) => props.transform};
  opacity: ${(props) => props.opacity};
  > div {
    height: ${(props) => props.height && props.height[0]}px;
  }
  @media ${device.desktop} {
    > div {
      height: ${(props) => props.height && props.height[2]}px;
    }
  }
  ${compose(space, position, layout)}

  svg {
    height: auto;
    width: auto;
    display: block;
    g {
      fill: ${(props) => props.color};
    }
    path {
      fill: ${(props) => props.color};
    }
  }
`

export const IconsContainer = styled.div``
