import React from "react"
import { ReactComponent as LanluasLogo } from "images/ll-logo.svg"
import { SocialLinks } from "UI/SocialLinks"
import { useStaticQuery, graphql } from "gatsby"
import { Box, StyledLink } from "UI"
import { MainColumn } from "../MainColumn"
import FooterCol from "./FooterCol"
import { Spacer } from "../Spacer"
import AustraliaGov from "images/australiaLogos/australiaGov.png"
import LocalGov from "images/australiaLogos/LocalGov.png"
import Badge from "images/gptw-badge.png"

import {
  FooterLink,
  Copyright,
  LogoWrapper,
  FooterLinksMenu,
  ColTitle,
  ImageWrapper,
  BadgeStyle,
} from "./style"

const Footer = () => {
  const data = useStaticQuery(
    graphql`
      {
        wp {
          siteOptions {
            siteSettings {
              linkCol1 {
                headingPage {
                  ... on WpPage {
                    uri
                    title
                  }
                }
                links {
                  ... on WpPage {
                    id
                    uri
                    title
                  }
                }
              }
              linkCol3 {
                headingPage {
                  ... on WpPage {
                    uri
                    title
                  }
                }
                links {
                  ... on WpPage {
                    uri
                    title
                  }
                }
              }
              footerText
              footerLinks {
                ... on WpPage {
                  title
                  uri
                }
              }
              instagramLink
              facebookLink
              twitterLink
              linkedinLink
              youtubeLink
              contactStrip {
                heading
                page {
                  ... on WpPage {
                    id
                    uri
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const siteSettings = data?.wp?.siteOptions?.siteSettings
  const col1 = siteSettings?.linkCol1
  const col3 = siteSettings?.linkCol3
  const contact = siteSettings?.contactStrip.page.uri
  const footerText = siteSettings?.footerText
  const footerLinks = siteSettings?.footerLinks

  return (
    <MainColumn>
      <Box
        mt={["20px", "53px"]}
        flexDirection={["column", null, "row"]}
        justifyContent={[null, null, "space-between"]}
      >
        <Box
          flexDirection={["row"]}
          justifyContent={["space-between"]}
          borderBottom={["1px solid #CED0D2", null, "none"]}
          pb={[28]}
          width={["100%", null, "48%"]}
        >
          <FooterCol heading={col1?.headingPage} links={col1?.links} />
          <FooterCol heading={{ title: "About Lánluas" }} links={col3?.links} />
        </Box>
        <Box
          mt={[30, null, 0]}
          pb={[35, null, 0]}
          flexDirection={["column", null, "row"]}
          justifyContent={["space-between"]}
          borderBottom={["1px solid #CED0D2", null, "none"]}
          width={["100%", null, "48%"]}
        >
          <Box
            width={["100%", null, "45%"]}
            pb={[35]}
            borderBottom={["1px solid #CED0D2", null, "none"]}
            flexDirection={["row", null, "column"]}
            justifyContent={["space-between"]}
          >
            <Box>
              <ColTitle>Follow Lánluas </ColTitle>
              <Spacer height={["15px"]} />
              <SocialLinks
                facebook={siteSettings?.facebookLink}
                linkedIn={siteSettings?.linkedinLink}
                twitter={siteSettings?.twitterLink}
                youtube={siteSettings?.youtubeLink}
              />
              <StyledLink
                to={contact}
                mt={[50, null, 38]}
                fontSize={[16, null, 18]}
              >
                Contact us
              </StyledLink>
            </Box>
            <BadgeStyle
              href="https://lanluas.com.au/97-of-employees-say-lanluas-is-a-great-place-to-work/"
              target="_blank"
            >
              <img src={Badge} alt="Great Place to Work" />
            </BadgeStyle>
          </Box>
          <Box width={["100%", null, "45%"]} mt={[20, null, 0]}>
            <ColTitle>Approved Supplier</ColTitle>
            <Box flexDirection={["row", null, "column"]} mt={[20, null, 15]}>
              <ImageWrapper>
                <img src={AustraliaGov} />
              </ImageWrapper>
              <ImageWrapper>
                <img src={LocalGov} />
              </ImageWrapper>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        mt={[28, null, 14]}
        pt={[null, null, 35]}
        borderTop={[null, null, "1px solid #CED0D2"]}
        flexDirection={[null, null, "row"]}
        justifyContent={[null, null, "space-between"]}
      >
        <Box
          justifyContent={["flex-start", null, "flex-start"]}
          flexDirection={[null, null, "row"]}
          alignItems={["flex-start", null, "flex-end"]}
        >
          <LogoWrapper to="/">
            <LanluasLogo />
          </LogoWrapper>
          <Spacer height={["18px"]} width={["100%", null, 0]} />
          <Copyright dangerouslySetInnerHTML={{ __html: footerText }} />
        </Box>
        <Box>
          <Spacer height={["18px"]} />
          <FooterLinksMenu>
            {footerLinks?.map((item, index) => {
              return (
                <FooterLink key={index} to={item.uri}>
                  {item.title}
                </FooterLink>
              )
            })}
          </FooterLinksMenu>
        </Box>
      </Box>
      <Box mt={[0, 16, 24]} mb={[30, null, 70]} maxWidth={700}>
        <Copyright>
          <p>
            Lánluas Consulting Pty Ltd is an independent consulting firm and is
            not affiliated with Technology One Ltd. Lánluas Consulting is not a
            reseller of Products, Solutions or Services from Technology One Ltd.
          </p>
        </Copyright>
      </Box>
    </MainColumn>
  )
}
export default Footer
