import React from "react"
import HomeHeader from "./HomeHeader"
import InternalHeader from "./InternalHeader"
import BlueHeader from "./BlueHeader"

const Header = ({ variant, pageContext }) => {
  switch (variant) {
    case "home":
      return <HomeHeader pageContext={pageContext} />

    default:
      if (pageContext.featuredImage)
        return <InternalHeader pageContext={pageContext} variant={variant} />
      return <BlueHeader pageContext={pageContext} variant={variant} />
  }
}

export default Header
