import React from "react"
import styled from "styled-components"
import { Icon } from "UI"
export const Arrow = styled(Icon)`
  position: absolute;
  z-index: 10;
`
export const ArrowsOverflow = () => {
  return (
    <>
      {/* 1 */}
      <Arrow
        name="ll-arrow"
        color="#FD8211"
        width={[61, 120, 120, 159]}
        top={[-46, -92, -92, -123]}
        left={[-19, -40]}
        opacity={0.95}
        clip="rect(-100px, -100px, auto, -100px)"
      />
      {/* 2 */}
      <Arrow
        name="ll-arrow"
        color="#47C5F3"
        width={[17, 0, 0, 0]}
        top={[50, 110]}
        left={[15, 82]}
        opacity={0.9}
      />
      {/* 3 */}
      <Arrow
        name="ll-arrow"
        color="#47C5F3"
        width={[31, 53]}
        top={[-7, -12]}
        left={["30%", "50%"]}
        opacity={0.9}
      />
      {/* 5 */}
      <Arrow
        name="ll-arrow"
        color="#47C5F3"
        width={[15, 53]}
        top={["75%", "40%"]}
        right={["75%", "25%"]}
        opacity={0.9}
      />
      {/* 6 */}
      <Arrow
        name="ll-arrow"
        color="#FD8211"
        width={[32, 53]}
        bottom={[-5, -40]}
        right={["8%", "5%"]}
        opacity={0.9}
      />
      {/* 7 */}
      <Arrow
        name="ll-arrow"
        color="#1870B0"
        width={[16, 33]}
        top={[36, 20]}
        right={["2%"]}
        opacity={0.6}
      />

      {/* 8 */}
      <Arrow
        name="ll-arrow"
        color="#32BCEF"
        width={[86, 110, 110, 159]}
        top={[-16, -39, -39, -78]}
        right={[-52, -100]}
        opacity={0.3}
      />
    </>
  )
}

export const ArrowsNoOverflow = () => {
  return (
    <>
      {/* 4 */}
      <Arrow
        name="ll-arrow"
        color="#1870B0"
        width={[52, 181]}
        bottom={[-13, -75]}
        right={["75%", "30%"]}
        opacity={0.8}
      />
    </>
  )
}
