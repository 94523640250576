import styled from "styled-components"
import Select from "react-select"
import { device } from "breakpoints"
export const SelectContainer = styled(Select)`
  width: 100%;
  margin-bottom: 25px;
  z-index: 2;
  @media ${device.desktop} {
    margin-bottom: 35px;
    width: 500px;
  }

  .css-1hwfws3 {
    padding: 0;
  }

  .basic__control {
    box-shadow: 0;
    background-color: #9e1164;
    border: none;
    outline: none;
    height: 50px;
    cursor: pointer;
    padding: 0 20px;

    :hover {
      border-color: 0;
      background-color: #84004b;
    }
  }

  .basic__value-container {
    padding: 0;
    border-radius: 0;
  }

  .basic__indicator-separator {
    display: none;
  }
  .basic__menu {
    margin-top: 0;
  }
  .basic__menu-list {
    padding-top: 0;
    padding-bottom: 0;
  }

  .basic__option {
    color: #9e1164;
    font-weight: 600;
    line-height: 16px;
    font-size: 16px;
    letter-spacing: 0;
    padding: 20px;
    padding-left: 10px;

    :active {
      background-color: #ccc;
    }
  }
  .basic__option--is-focused {
    background-color: #eee;
    transition: 90ms;
    cursor: pointer;
  }

  .basic__option--is-selected {
    background-color: #ccc;
    font-weight: 600;
    color: #9e1164;
  }
`

export const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    transform: rotateX(${(props) => (props.open ? "180deg" : "0")});
    transition: ${(props) => (props.open ? "300ms" : "300ms")};
    path {
      stroke: white;
    }
  }
`

export const SelectedValue = styled.div`
  span {
    font-weight: 600;
    color: white;
  }
`
export const CustomOption = styled.div`
  position: relative;
  margin-left: 8px;
  padding: 12px 0;
  cursor: pointer;

  @media ${device.desktop} {
    margin: 0;
    padding: 0 20px;
  }

  span {
    font-family: ${(props) => props.theme.headerFont};
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    color: ${(props) => props.theme.colors.celeste};
    text-transform: uppercase;
    padding: 0;
    position: relative;
    border-bottom: ${(props) => props.isSelected && "2px solid #47C5F3"};
    color: #9fe5ff;
    color: ${(props) => props.isSelected && "white"};
    transition: 100ms;
  }

  :hover {
    span {
      color: white;

      ::before {
        position: absolute;
        content: "";
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 2px;
        background: #47c5f3;
      }
    }
  }
`
