import React from "react"
import { Arrow } from "./style"
import { Box, Text, MainColumn } from "UI"
import { Breadcrumbs } from "../Breadcrumbs"

const BlueHeader = ({ pageContext, variant }) => {
  const { title, ancestors, headerSettings } = pageContext
  const { customPageTitle } = headerSettings || {}
  const pageTitle = customPageTitle || title

  const Arrows = () => {
    return (
      <>
        {/* 1 */}
        <Arrow
          name="ll-arrow"
          color="#47C5F3"
          width={[51, null, 60, 125]}
          top={[15, null, 50, 49]}
          left={[-50, null, -60, -180]}
          opacity={0.9}
        />
        {/* 2 */}
        <Arrow
          name="ll-arrow"
          color="#1870B0"
          width={[15, null, 20, 35]}
          top={[80, null, 160, 215]}
          left={[-10, null, -45, -85]}
          opacity={0.9}
        />
        {/* 3 */}
        <Arrow
          name="ll-arrow"
          color={["#47C5F3"]}
          width={[69, null, 90, 138]}
          top={[85, null, 70]}
          right={[0, null, null, -80]}
          opacity={0.8}
        />
        {/* 4 */}
        <Arrow
          name="ll-arrow"
          color={["#1870B0"]}
          width={[18, null, 23, 35]}
          top={[80, 60]}
          right={[-10, null, -20, -120]}
          opacity={0.7}
        />

        {/* 5 */}
        <Arrow
          name="ll-arrow"
          color={["#1870B0"]}
          width={[142, 190, 290]}
          top={[-42, -40, -60]}
          left={["30%", "40%", "55%"]}
          opacity={0.5}
        />
      </>
    )
  }

  return (
    <Box
      width="100%"
      mb={[25, 40, 75]}
      background="#008CD1"
      overflow="hidden"
      id="header"
    >
      <MainColumn>
        <Box
          position="relative"
          //  minHeight={[200, null, 320, null, 470]}
        >
          <Arrows />

          {/* text block */}
          <Box
            p={["44px 20px", "60px 20px", "82px 0 75px 0", null, "100px 0"]}
            order={[2, 1]}
            position="relative"
            zIndex={12}
            pr={[90]}
            mt="auto"
          >
            <Breadcrumbs
              ancestors={ancestors?.nodes}
              alt
              currentPage={title}
              variant={variant}
            />
            <Text
              as="h1"
              fontSize={[32, null, 48]}
              color="white"
              maxWidth={920}
            >
              {pageTitle}
            </Text>
          </Box>
        </Box>
      </MainColumn>
    </Box>
  )
}

export default BlueHeader
