import { useState } from "react"
import { useForm as useReactForm, Controller } from "react-hook-form"
export const useForm = () => {
  const [submitted, setSubmitted] = useState(false)

  const { register, watch, errors, handleSubmit, control } = useReactForm()
  const inputProps = { register: register, errors: errors, watch: watch }

  return {
    handleSubmit,
    inputProps,
    register,
    watch,
    errors,
    Controller,
    control,
    setSubmitted,
    submitted,
  }
}
