import React from "react"
import { BreadcrumbsContainer, PresentPage, PrimaryLink } from "./style"
import { Icon } from "../Icon"

const Breadcrumbs = ({ variant, ancestors, alt, currentPage }) => {
  const orderedAncestors = ancestors
    ? ancestors.sort((a, b) => a.depth - b.depth)
    : null

  return (
    <BreadcrumbsContainer>
      <PrimaryLink to="/" alt={alt}>
        HOME
      </PrimaryLink>
      {orderedAncestors?.length &&
        orderedAncestors?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <Icon
                name="ll-arrow"
                width="6px"
                height="8px"
                color={alt ? "white" : "#32BCEF"}
                m="0 10px"
                mt="-2px"
              />

              <PrimaryLink to={item.uri} alt={alt}>
                {item.title}
              </PrimaryLink>
            </React.Fragment>
          )
        })}

      <Icon
        name="ll-arrow"
        width="6px"
        height="8px"
        color={alt ? "white" : "#32BCEF"}
        m="0 10px"
        mt="-2px"
      />

      <PresentPage alt={alt}>
        {variant === "page" || variant === "post" || variant === "casestudy"
          ? "Details"
          : currentPage === "Case Study"
          ? "Case Studies"
          : currentPage}
      </PresentPage>
    </BreadcrumbsContainer>
  )
}

export default Breadcrumbs
