import React from "react"
import { AvenueButton, Label } from "./style"

const Button = ({ to, onClick, className, children, type, ...rest }) => {
  const isButton = onClick || type === "submit"
  return (
    <AvenueButton
      as={isButton && "button"}
      onClick={() => onClick && onClick()}
      to={to || "/"}
      type={type}
      className={`${className} avenueBtn`}
      {...rest}
    >
      <Label className="label">{children}</Label>
    </AvenueButton>
  )
}

export default Button
