import styled from "styled-components"
import { Link } from "gatsby"
import { device } from "breakpoints"
import { MainColumn } from "UI/MainColumn"

export const NavBarContainer = styled(MainColumn)`
  position: relative;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  padding: 15px 0;
  align-items: center;
  z-index: 100;
  @media ${device.desktop} {
    padding: 23px 0;
  }
`

export const LogoWrapper = styled(Link)`
  display: flex;
  align-items: center;
  height: auto;

  > svg,
  > img {
    width: 134px;
    height: 32px;
    display: block;

    @media ${device.desktop} {
      width: 201px;
      height: 48px;
    }
  }
`

export const NavWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const NavBarWrapper = styled.div`
  position: relative;
`

export const Nav = styled.nav`
  position: absolute;
  bottom: 0;
  transform: translate3D(-50%, 100%, 0);
  left: 50%;
  margin: 0;
  background: ${(props) => props.theme.primaryColor};
  width: 100vw;
  display: ${(props) => (props.isNavShown ? "flex" : "none")};
  z-index: 12;

  @media ${device.desktop} {
    display: flex;
    position: static;
    bottom: auto;
    left: auto;
    width: 100%;
    background: none;
    transform: none;
    justify-content: flex-end;
  }
`

export const NavList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  @media ${device.desktop} {
    flex-direction: row;
  }
`

export const NavListItem = styled.li`
  width: 100%;
  font-weight: 600;
  color: ${(props) => props.theme.linkColor};
  padding: 10px 20px;
  position: relative;

  @media ${device.desktop} {
    width: auto;
  }

  a {
    display: block;
    text-align: center;
    color: inherit;

    ::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  /* hover style */
  span {
    position: relative;

    ::before {
      content: "";
      position: absolute;
      left: 0;
      opacity: 0;
      height: 2px;
      width: 100%;
      bottom: -6px;
      transition: 200ms;
      background: ${(props) => props.theme.linkColor};
    }
  }

  :hover {
    span::before {
      opacity: 1;
      bottom: -3px;
    }
  }
`

export const QuickSearchWrapper = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0 20px;
  max-height: 60px;
  height: 100%;
  opacity: 1;
  transition: 110ms;

  :hover {
    opacity: 0.7;
  }
  svg {
    height: 20px;
    width: 20px;
  }
`

export const Clickable = styled.div`
  display: block;
  height: 100%;
  width: 100%;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.desktop} {
    display: none;
  }
`

export const HamburgerWrapper = styled.div`
  display: inline-block;
  width: 42px;
  height: 42px;
  border-radius: 42px;
  background: ${(props) => props.theme.secondaryColor};
  position: relative;
  display: flex;
  justify-content: center;

  cursor: pointer;
`

export const FirstLine = styled.span`
  display: block;
  height: 2px;
  width: 20px;
  margin-top: 13px;
  transition: 0.4s ease-out;
  background: #fff;
  position: absolute;
  transform: ${(props) =>
    props.isNavShown
      ? "translate3d(0, 6px, 0) rotate(45deg)"
      : "translate3d(0, 0, 0) rotate(0deg)"};
`

export const SecondLine = styled(FirstLine)`
  transition: 0.1s ease-out;
  opacity: ${(props) => (props.isNavShown ? 0 : 1)};
  top: 8px;
`

export const ThirdLine = styled(FirstLine)`
  transform: ${(props) =>
    props.isNavShown
      ? "translate3d(0, 6px, 0) rotate(-45deg)"
      : "translate3d(0, 16px, 0) rotate(0deg)"};
  display: block;
`
