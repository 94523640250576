import styled from "styled-components"
import { device } from "breakpoints"

export const NestedNav = styled.nav`
  display: ${(props) => (props.subMenu ? "block" : "none")};
  top: 0;
  left: 0;
  width: 100%;
  background: ${(props) => props.theme.colors.darkBlue};
  z-index: 6;
  @media ${device.desktop} {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 15px;
    transform: translateY(100%);
    width: 280px;
    flex-direction: column;
    background: ${(props) => props.theme.colors.indigo};
    border-radius: 4px;
    box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.15);
  }

  ul {
    list-style: none;
    width: 100%;
  }
`

export const ThirdLevelContainer = styled(NestedNav)`
  @media ${device.desktop} {
    display: none !important;
    left: -280px;
    bottom: 52px;
    background: ${(props) => props.theme.colors.darkBlue};
    border-radius: 4px 0 0 4px;
  }
`

export const NavListItem = styled.li`
  width: 100%;
  font-weight: 700;
  color: ${(props) => props.theme.linkColor};
  font-size: 16px;
  line-height: 24px;

  @media ${device.desktop} {
    width: auto;
    position: relative;
    font-size: 14px;
    color: ${(props) => props.theme.colors.charcoal};
  }

  a {
    display: block;
    font-size: 16px;
    text-align: left;
    color: inherit;
    /* padding: 10px 20px 10px 28px; */
    width: 100%;
    position: relative;

    ::after {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 130%;
      z-index: 5;
      display: none;
    }
  }

  span {
    position: relative;
    text-transform: uppercase !important;
  }

  /* opens last NestedNav inwards, can set for 2nd to last too if needed */
  :last-of-type {
    ${NestedNav} {
      @media ${device.desktop} {
        left: auto;
        right: 0;
      }
    }
  }

  :hover {
    a:after {
      display: block;
    }
    span::before {
      opacity: 1;
      bottom: -3px;
    }

    /* displays nestedNav on hover @ desktop */
    ${(props) =>
      props.menu &&
      `
    @media ${device.desktop} {
      border-radius: 0 4px 4px 0;
      ::before {
        content: "";
        transform: translateY(100%);
        position: absolute;
        bottom: 30px;
        left: 0;
        width: 200%;
        height: 100px;
      }
      ${NestedNav} {
        display: flex;
      }
    }
  `};
  }
`

export const NestedNavListItem = styled.li`
  width: 100%;
  transition: 70ms;
  display: ${(props) => (props.megaMenu ? "flex" : "block")};

  :last-child {
    padding-bottom: 10px;
  }

  @media ${device.desktop} {
    position: relative;
    color: #fff;

    :hover {
      ${ThirdLevelContainer} {
        border-radius: 4px 0 0 4px;
        cursor: pointer;
        display: block !important;
        background: ${(props) => props.theme.colors.darkBlue};
      }
    }
  }
  a {
    text-align: left;
    padding: 15px 60px 9px 32px;

    @media ${device.desktop} {
      padding: 15px 60px 13px 18px;
    }
  }

  :hover {
    background: ${(props) => props.theme.colors.indigo};
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    @media ${device.desktop} {
      background: ${(props) => props.theme.colors.darkBlue};

      border-radius: 0 0 0 0;
      :first-of-type {
        border-radius: 4px 4px 0 0;
      }
      :last-of-type {
        border-radius: 0 0 4px 4px;
      }
    }
  }
`

export const ThirdLEvelListItem = styled(NestedNavListItem)`
  :hover {
    background: ${(props) => props.theme.colors.dodgerBlue};
    border-radius: 4px 0 0 4px;
  }
`

export const FirstLevel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.5);

  @media ${device.desktop} {
    border: none;
    :hover {
      cursor: pointer;
      span {
        color: ${(props) => props.theme.colors.indigo};
      }
    }
  }

  span {
    margin-left: 30px;
    font-family: ${(props) => props.theme.headerFont};
  }
`

export const IconContainer = styled.div`
  background: ${(props) => props.theme.colors.darkBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  cursor: pointer;

  @media ${device.desktop} {
    display: none;
  }
`
