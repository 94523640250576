import React from "react"
import styled from "styled-components"
import { Icon } from "UI"
export const Arrow = styled(Icon)`
  position: absolute;
  z-index: 0;
`
export const Arrows = () => {
  return (
    <>
      {/* 1 */}
      <Arrow
        name="ll-arrow"
        color="#47C5F3"
        width={[56, 111]}
        height={[84, 168]}
        bottom={[-5, 50]}
        right={["55%", "22%", "15%"]}
        opacity={0.4}
      />
      {/* 2 */}
      <Arrow
        name="ll-arrow"
        color="#1870B0"
        width={[110, 219]}
        height={[168, 334]}
        bottom={[-48, -31]}
        right={["30%", "2%"]}
        opacity={0.8}
      />
    </>
  )
}

