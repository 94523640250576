import React, { useState, useEffect } from "react"
import { Box, MainColumn, Text } from "UI"
import { useMedia } from "helpers/useMedia.js"
import { Filter } from "./style"

const Filters = ({ filter, setFilter, setCurrentPage, filters }) => {
  const defaultValue = filters.find((option) => option.value === filter)
  const [value, setValue] = useState(defaultValue)

  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const handleSelect = (e) => {
    setValue(e)
    setFilter(e.value)
    setCurrentPage(1)
  }

  const isDesktop = useMedia(
    // Media queries
    ["(min-width: 1024px)"],
    //options
    [true],
    // default
    false
  )

  useEffect(() => {
    isDesktop && setMenuIsOpen(true)
    !isDesktop && setMenuIsOpen()
  }, [isDesktop])

  return (
    <Box
      m={["0 auto"]}
      mt={[-25, -40, -75]}
      p={[null, null, "14px"]}
      width={["100%"]}
      backgroundColor={["#008cd1"]}
      position="relative"
      z-index={100}
    >
      <MainColumn>
        <Box
          flexDirection={[null, null, "row"]}
          alignItems={[null, null, "center"]}
        >
          {isDesktop && (
            <Text fontSize={["14px"]} fontWeight={["700"]} color={["#fff"]}>
              View:
            </Text>
          )}
          <Filter
            options={filters}
            placeholder={isDesktop ? "View: " : "Filter results: "}
            value={value}
            onChange={handleSelect}
            menuIsOpen={isDesktop || menuIsOpen}
          />
        </Box>
      </MainColumn>
    </Box>
  )
}

export default Filters
