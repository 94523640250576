import React from "react"
import AvenueLink from "UI/AvenueLink"
import { Icon, Text } from "UI"
import styled from "styled-components"
import {
  compose,
  space,
  position,
  layout,
  flexbox,
  typography,
} from "styled-system"

export const StyledLinkComponent = styled(AvenueLink)`
  display: inline-flex;
  justify-self: flex-start;
  align-self: flex-start;
  font-weight: bold;
  color: #000;
  align-items: center;
  transition: 110ms;
  position: relative;

  :hover {
    color: #008cd1;
    .icon {
      svg {
        path {
          transition: 110ms;

          fill: #ec5500;
        }
      }
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 150%;
    height: 150%;
  }

  ${compose(space, position, layout, flexbox, typography)}
`
const StyledLink = (props) => {
  return (
    <StyledLinkComponent {...props}>
      <Text as="span" fontSize={[16, null, 18]} mr={[10, null, 15]}>
        {props.children}
      </Text>
      <Icon name="ll-arrow" width="11px" color="#FD8211" />
    </StyledLinkComponent>
  )
}

export default StyledLink
