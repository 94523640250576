import styled from "styled-components"
import { device } from "breakpoints"
import { SelectInput } from "../Form/SelectInput"

export const Filter = styled(SelectInput)`
  margin-bottom: 0;
  @media ${device.desktop} {
    width: 100% !important;
    z-index: 11;
    margin-bottom: 0;
  }

  .basic__menu-list {
    padding-left: 20px;
    margin-bottom: 20px;

    @media ${device.desktop} {
      display: flex !important;
      padding-left: 13px;
      margin-bottom: 0;
    }
  }

  .basic__control {
    background-color: #008cd1;
    border-radius: 0;
    padding: 0;
    box-shadow: none;
    height: 60px;

    :hover {
      background-color: #008cd1;
    }

    @media ${device.desktop} {
      display: none;
    }

    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      color: #ffffff;
    }
  }

  .basic__menu {
    background-color: #008cd1;
    border-radius: 0;
    margin-bottom: 0;
    width: calc(100% + 40px);
    margin-left: -20px;
    box-shadow: none;

    @media ${device.desktop} {
      margin-left: 0;
      position: relative;
      box-shadow: none;
    }
  }
`
